<template>
  <div class="container_con">
    <!-- <el-card style="margin-bottom: 15px;" v-if="data">
      <div class="con_title"><span></span> 销售计划单详情</div>
      <div style="line-height: 24px; font-size: 14px;">
          <el-row :gutter="20" style="margin-bottom: 10px;">
              <el-col :span="6">开票日期：{{data.sale_plan.sale_plan_date}}</el-col>
              <el-col :span="6">开票人：{{data.sale_plan_admin_name}}</el-col>
              <el-col :span="6">部门：{{data.sale_plan_department_name}}</el-col>
              <el-col :span="6">销售单位：{{data.sale_plan.merchant.merchant_name}}</el-col>
          </el-row>
          <el-row :gutter="20">
              <el-col :span="6">单据编号：{{data.sale_plan.sale_plan_no}}</el-col>
              <el-col :span="6">单位编号：{{data.sale_plan.merchant.merchant_no}}</el-col>
          </el-row>
      </div>
    </el-card> -->
    
    <el-card style="margin-bottom: 15px;" v-if="data">
      <div class="con_title"><span></span> 销售退回单详情</div>
      <div style="line-height: 24px; font-size: 14px;">
          <el-row :gutter="20" style="margin-bottom: 10px;">
              <el-col :span="6">开票日期：{{data.sale_return_date}}</el-col>
              <el-col :span="6">单据编号：{{data.sale_return_no}}</el-col>
              <el-col :span="8" v-if="!have_auth('/salesBack/edit_admin')">开票人：{{data.admin_name}}</el-col>
              <el-col :span="8" v-if="have_auth('/salesBack/edit_admin')">开票人：
                <el-select v-model="data.admin_id" placeholder="请选择"
                    style="width: 200px; " size="mini" filterable @change="admin_change">
                    <el-option :label="item.full_name+'('+item.department_name+')' + '-' + item.mnemonic_code" v-for="(item,index) in ywlist"
                        :key="index" :value="item.id"></el-option>
                </el-select>
              </el-col>
              <el-col :span="6">备注：{{data.remark}}</el-col>
          </el-row>
      </div>
      <div class="" style="margin-top: 10px; font-size: 14px;">
          <div style="padding-bottom: 20px;">退回产品：</div>
          <div class="">
              <el-table :data="data.sale_return_product" style="width: 100%">
                  
                <el-table-column label="产品编号">
                    <template v-slot="scope">
                      {{JSON.parse(JSON.parse(scope.row.sale_out_product.sale_plan_product).product).product_no}}
                    </template>
                </el-table-column>
                <el-table-column label="品名">
                    <template v-slot="scope">
                      {{JSON.parse(JSON.parse(scope.row.sale_out_product.sale_plan_product).product).product_name}}
                    </template>
                </el-table-column>
                
                <el-table-column label="型号规格">
                    <template v-slot="scope">
                      {{JSON.parse(JSON.parse(scope.row.sale_out_product.sale_plan_product).product).model}}
                    </template>
                </el-table-column>
                <el-table-column label="生产厂家">
                    <template v-slot="scope">
                      {{JSON.parse(JSON.parse(scope.row.sale_out_product.sale_plan_product).product).manufacturer}}
                    </template>
                </el-table-column>
                <el-table-column label="包装单位">
                    <template v-slot="scope">
                      {{scope.row.pack_unit_name}}
                    </template>
                </el-table-column>
                <el-table-column label="数量">
                    <template v-slot="scope">
                      {{scope.row.product_num}}
                    </template>
                </el-table-column>
                <el-table-column label="单价">
                    <template v-slot="scope">
                      {{scope.row.product_price}}
                    </template>
                </el-table-column>
                <el-table-column label="金额">
                    <template v-slot="scope">
                      <span style="color: red;">{{(parseFloat(scope.row.product_num)*parseFloat(scope.row.product_price)).toFixed(4)}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="批号/生产日期">
                    <template v-slot="scope">
                      {{scope.row.batch_number}}
                    </template>
                </el-table-column>
                <el-table-column label="有效期至">
                    <template v-slot="scope">
                      {{scope.row.valid_until || '长效期'}}
                    </template>
                </el-table-column>
                <el-table-column label="注册证名">
                    <template v-slot="scope">
                      {{JSON.parse(JSON.parse(scope.row.sale_out_product.sale_plan_product).product).register_cert_name}}
                    </template>
                </el-table-column>
                <el-table-column label="注册证号">
                    <template v-slot="scope">
                      {{JSON.parse(JSON.parse(scope.row.sale_out_product.sale_plan_product).product).register_cert_no}}
                    </template>
                </el-table-column>
                
              </el-table>
          </div>
      </div>
      <el-button @click="$router.go(-1)" style="margin-top: 20px;">返回</el-button>
    </el-card>
    
  </div>
</template>

<script>
export default {
  name: "addpurchaseAccept",
  data() {
    return {
      id: '',
      is_ajax: false,
      form: {
          type:'1'
      },
      
      list:[],
      data:'',
      have_sele_arr:[],
      
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ywlist:[]
    };
  },
  created() {
      this.id=this.$route.query.id || '';
      this.get_data();
      if(this.have_auth('/salesBack/edit_admin')){
          this.getOptions()
      }
  },
  methods: {
    admin_change(e){
        if(!e){return false;}
        this.$httpPost("/backend/SaleReturn/modify_salesman", {id:this.id,admin_id:e}).then((res) => {
            if (res.status == 200) {
                this.$message.success('开票人修改成功');
            } else {
                this.$message.error(res.message);
            }
        }).catch((err) => {
            console.log(err);
        });
    },
    getOptions(){
        // 获取业务员 ywlist
        this.$httpGet("/backend/sys.Admin/salesmanList", {size:10000000}).then((res) => {
            if (res.status == 200) {
                this.ywlist = res.data.data;
            } else {
                this.$message.error(res.message);
            }
        }).catch((err) => {
            console.log(err);
        });
    },
    get_data(){
        this.$httpGet("/backend/SaleReturn/read", {id:this.id}).then((res) => {
            if (res.status == 200) {
                this.data=res.data;
                this.have_sele_arr=[...res.data.sale_out_product]
            } else {
                this.$message.error(res.message);
            }
        }).catch((err) => {
            console.log(err);
        });
    },
    
  },
};
</script>

<style lang="scss" scoped>
.table_Box {
  padding-left: 50px;
  font-size: 14px;
  .table_title {
    span {
      color: #1676e0;
      cursor: pointer;
    }
  }
  .el-table {
    margin: 30px 0;
  }
}
.top_box {
  display: flex;
  justify-content: space-around;
}
.examin {
  display: flex;
  justify-content: space-between;
}
</style>
